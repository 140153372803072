<template>
  <div class="home-class">
    <div class="power-station">
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom: 10px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="18">
            <el-button type="primary" @click="newBtn">新建</el-button>
            <exportBtn :params="expParams" />
          </el-col>
          <el-col :span="6">
            <el-input
              placeholder="请输入内容"
              v-model="queryInfo.condition.powerStationName"
              class="input-with-select"
              clearable
            >
              <el-button
                slot="append"
                type="primary"
                icon="el-icon-search"
                @click="search"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>

      <!-- 表格部分 -->
      <div>
        <el-table
          border
          ref="table"
          class="scroll-table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          height="75vh"
        >
          <el-table-column label="操作" width="135" align="center" fixed>
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" size="small"
                >编辑</el-button
              >
              <!-- <el-popconfirm
                title="确定删除吗？"
                @onConfirm="handleDelte(scope.row.id)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm> -->
              <el-button
                slot="reference"
                type="text"
                @click="handleDelte(scope.row.id)"
                >删除</el-button
              >
              <el-button
                type="text"
                @click="distribution(scope.row)"
                size="small"
                >分配</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>

          <el-table-column label="电站名称" width="180" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.powerStationName
            }}</template>
          </el-table-column>
          <el-table-column label="电站简称" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.powerStationAliasName
            }}</template>
          </el-table-column>
          <!-- <el-table-column
            label="电站时区"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.timeZone }}</template>
          </el-table-column> -->
          <el-table-column label="电站地址" width="250" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.address }}</template>
          </el-table-column>
          <el-table-column
            label="经度"
            align="center"
            width="100px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.longitude }}</template>
          </el-table-column>
          <el-table-column
            label="纬度"
            align="center"
            width="100px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.latitude }}</template>
          </el-table-column>
          <el-table-column
            label="电站负责人"
            align="center"
            show-overflow-tooltip
            width="90px"
          >
            <template slot-scope="scope">{{ scope.row.heador }}</template>
          </el-table-column>
          <el-table-column label="联系人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.linkMan }}</template>
          </el-table-column>
          <el-table-column
            label="联系电话"
            align="center"
            show-overflow-tooltip
            width="100"
          >
            <template slot-scope="scope">{{ scope.row.linkPhone }}</template>
          </el-table-column>
          <el-table-column
            label="电站状态"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                style="color: #67c23a"
                v-if="scope.row.powerStationStatus == 1"
                >正常</span
              >
              <span
                style="color: #e6a23c"
                v-if="scope.row.powerStationStatus == 2"
                >故障</span
              >
              <span
                style="color: #f56c6c"
                v-if="scope.row.powerStationStatus == 3"
                >通讯中断</span
              >
            </template>
          </el-table-column>
          <el-table-column label="电站类型" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.stationType }}</template>
          </el-table-column>
          <el-table-column
            label="已投产容量（KWp）"
            align="center"
            show-overflow-tooltip
            width="135"
          >
            <template slot-scope="scope">{{ scope.row.volume }}</template>
          </el-table-column>
          <el-table-column
            label="并网时间"
            align="center"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.onGridTime }}</template>
          </el-table-column>
          <el-table-column
            label="并网类型"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.onGridType }}</template>
          </el-table-column>
          <!-- <el-table-column
            label="厂站规划"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.stationPlant }}</template>
          </el-table-column>
          <el-table-column
            label="安全生产开始时间"
            align="center"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.runStartTime }}</template>
          </el-table-column>
          <el-table-column
            label="数据来源"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.dataSources }}</template>
          </el-table-column> -->
          <el-table-column
            label="厂站图片"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.logo"
                :preview-src-list="[scope.row.logo]"
              >
              </el-image> -->
              <el-button
                type="text"
                @click="showImg(scope.row.logo)"
                size="small"
                >查看图片</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="电站简介"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.powerStationIntro
            }}</template>
          </el-table-column>
          <el-table-column
            label="统一社会信用代码"
            align="center"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.creditCode }}</template>
          </el-table-column> -->
          <!-- <el-table-column label="海拔" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.altitude }}</template>
          </el-table-column> -->
          <!-- <el-table-column
            label="当前功率（w）"
            align="center"
            show-overflow-tooltip
            width="110"
          >
            <template slot-scope="scope">{{
              scope.row.currEfficiency
            }}</template>
          </el-table-column>
          <el-table-column
            label="当日发电量(度)"
            align="center"
            show-overflow-tooltip
            width="110"
          >
            <template slot-scope="scope">{{
              scope.row.currDayPowerNum
            }}</template>
          </el-table-column>
          <el-table-column
            label="本月发电量(度)"
            align="center"
            show-overflow-tooltip
            width="110"
          >
            <template slot-scope="scope">{{
              scope.row.monthPowerNum
            }}</template>
          </el-table-column>
          <el-table-column
            label="本年发电量(度)"
            align="center"
            show-overflow-tooltip
            width="110"
          >
            <template slot-scope="scope">{{ scope.row.yearPowerNum }}</template>
          </el-table-column>
          <el-table-column
            label="累计发电量(度)"
            align="center"
            show-overflow-tooltip
            width="110"
          >
            <template slot-scope="scope">{{
              scope.row.totalPowerNum
            }}</template>
          </el-table-column>
          <el-table-column
            label="当日收益(元)"
            align="center"
            show-overflow-tooltip
            width="100"
          >
            <template slot-scope="scope">{{
              scope.row.currDayIncome
            }}</template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
          </el-table-column>
          <el-table-column
            label="修改时间"
            align="center"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.updateTime }}</template>
          </el-table-column>
          <el-table-column
            label="供电方式"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.supplyPowerMode
            }}</template>
          </el-table-column>
          <el-table-column
            label="光伏并网模式"
            align="center"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">{{ scope.row.onGridMode }}</template>
          </el-table-column> -->

          <!-- <el-table-column label="排序" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.seq }}</template>
          </el-table-column> -->
          <!-- <el-table-column
            label="中控电话"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.centralControlPhone
            }}</template>
          </el-table-column>
          <el-table-column
            label="反射率（0-1）"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.reflectivity }}</template>
          </el-table-column> -->
          <el-table-column
            label="设计装机容量(kw)"
            align="center"
            show-overflow-tooltip
            width="130"
          >
            <template slot-scope="scope">{{ scope.row.designVolume }}</template>
          </el-table-column>

          <el-table-column
            label="省份名称"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.provinceName }}</template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        :title="labelType == 'add' ? '新增' : '编辑'"
        :visible.sync="showDialog"
        width="800px"
      >
        <el-form
          class="form-class"
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="电站名称：" prop="powerStationName">
                <el-input
                  v-model="form.powerStationName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站简称：" prop="powerStationAliasName">
                <el-input
                  v-model="form.powerStationAliasName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="电站地址：" prop="address">
                <el-input
                  v-model="form.address"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="经度：" prop="longitude">
                <el-input
                  v-model="form.longitude"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度：" prop="latitude">
                <el-input
                  v-model="form.latitude"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="电站负责人：" prop="heador">
                <el-input v-model="form.heador" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人：" prop="linkMan">
                <el-input
                  v-model="form.linkMan"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="联系电话：" prop="linkPhone">
                <el-input
                  v-model="form.linkPhone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站类型：" prop="stationType">
                <!-- <el-input
                  v-model="form.stationType"
                  placeholder="请输入"
                ></el-input> -->
                <el-select v-model="form.stationType" placeholder="请选择电站类型">
                  <el-option
                      v-for="item in stationTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="公司名称：" prop="companyName">
                <el-input
                  v-model="form.companyName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度：" prop="longitude">
                <el-input
                  v-model="form.longitude"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度：" prop="latitude">
                <el-input
                  v-model="form.latitude"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="厂站时区：" prop="timeZone">
                <el-input
                  v-model="form.timeZone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="厂站负责人：" prop="heador">
                <el-input v-model="form.heador" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="12">
              <el-form-item label="联系电话：" prop="linkPhone">
                <el-input
                  v-model="form.linkPhone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="电站容量：" prop="volume">
                <el-input v-model="form.volume" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="并网时间：" prop="onGridTime">
                <el-date-picker
                  v-model="form.onGridTime"
                  type="date"
                  style="width: 100%;"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="屋顶结构：" prop="roofStructure">
                <el-input
                  v-model="form.roofStructure"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安全运行开始时间：" prop="runStartTime">
                <el-date-picker
                  v-model="form.runStartTime"
                  type="date"
                  style="width: 100%;"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer">
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave('form')"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-dialog title="分配" :visible.sync="distributionDialog" width="70%">
      <div class="org-selected">
        <el-row>
          <!-- <el-col :span="6" class="selectedUl pr10 bbox">
            <span class="span"></span>
            <p class="title">公司名称</p>
            <div class="selectedUl-box topmar">
              <ul>
                <li
                  :class="deptName == item.deptName ? 'active lis' : 'lis'"
                  v-for="(item, index) in companyList"
                  :key="index"
                  @click="queryCompanyUser(item)"
                >
                  <span class="floatLeft rightOrderBox">{{
                    item.deptName
                  }}</span>
                </li>
              </ul>
            </div>
          </el-col> -->
          <el-col :span="24">
            <!-- <span class="span2"></span>
            <p class="title">公司人员列表</p> -->
            <el-table
              class="fontSize-table"
              highlight-current-row
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '0px' }"
              header-row-class-name="table-head-row"
              :data="companyUserList"
              border
              height="calc(100vh - 296px)"
              style="width: 100%; margin-top: 20px"
              v-loading="loading"
              @selection-change="handleSelectionChange"
              ref="multipleTable"
            >
              <el-table-column type="selection"> </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column
                width="60"
                show-overflow-tooltip
                label="姓名"
                align="center"
                min-width="140"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.familyName }}{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="员工账号"
                prop="loginName"
                align="center"
                width="110"
              >
              </el-table-column>
              <el-table-column
                min-width="140px"
                label="所属公司名称"
                align="center"
                prop="companyName"
              ></el-table-column>
              <el-table-column
                width="90"
                label="部门名称"
                align="center"
                prop="deptName"
              ></el-table-column>
              <el-table-column
                width="120"
                label="岗位名称"
                align="center"
                prop="postName"
              ></el-table-column>
              <el-table-column
                width="160px"
                label="创建时间"
                align="center"
                prop="createTime"
              ></el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination @size-change="handleSizeChangeAuthority"
                            @current-change="handleCurrentChangeAuthority" :current-page="queryInfoAuthority.currPage"
                            :page-size="queryInfoAuthority.pageSize" layout="total, prev, pager, next"
                            :total="totleAuthority" class="ml10" style="margin-bottom: 15px"></el-pagination> -->
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveDistribution">确 认</el-button>
        <el-button @click="distributionDialog = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-image-viewer
      v-if="showViewer"
      :zIndex="8000"
      :on-close="closeViewer"
      :url-list="previewImgList"
    />
  </div>
</template>

<script>
import { formatDate } from "../../../utils/formatDate";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import exportBtn from "@/components/exportBtn.vue";
export default {
  components: { ElImageViewer, exportBtn },
  data() {
    return {
      labelType: "add",
      distributionDialog: false, //分配
      companyList: [], // 分配公司名称
      companyUserList: [], //公司员工列表
      multipleSelection: [], //选中列表
      loading: true,
      deptName: "",

      rules: {
        powerStationName: [{ required: true, message: "电站名称不能为空" }],
        powerStationAliasName: [
          { required: true, message: "电站简称不能为空" }
        ],
        timeZone: [{ required: true, message: "时区不能为空" }],
        address: [{ required: true, message: "电站地址不能为空" }],
        heador: [{ required: true, message: "电站负责人不能为空" }],
        linkMan: [{ required: true, message: "联系人不能为空" }],
        linkPhone: [{ required: true, message: "联系人电话不能为空" }],
        onGridTime: [{ required: true, message: "并网时间不能为空" }],
        // runStartTime: [{ required: true, message: "安全生产开始时间不能为空" }],
        createTime: [{ required: true, message: "创建时间不能为空" }],
        updateTime: [{ required: true, message: "修改时间不能为空" }],

        companyName: [{ required: true, message: "公司名称不能为空" }],
        longitude: [{ required: true, message: "经度不能为空" }],
        latitude: [{ required: true, message: "纬度不能为空" }],
        volume: [{ required: true, message: "电容量不能为空" }],
        roofStructure: [{ required: true, message: "屋顶结构不能为空" }]
      },
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 190, //表格高度
      headerHeight: 0,
      showViewer: false,
      previewImgList: [],
      expParams: {
        url: "",
        fileName: ""
      },
      stationTypeList:[
        { label: '高压-彩钢瓦', value: '高压-彩钢瓦' },
        { label: '高压-水泥顶', value: '高压-水泥顶' },
        { label: '低压-彩钢瓦', value: '低压-彩钢瓦' },
        { label: '低压-水泥顶', value: '低压-水泥顶' },
      ],
    };
  },

  created() {
    this.getList();
    // this.getCompanyUserList()
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },
  methods: {
    //图片上传成功处理
    handleUploadSuccess(res, file) {
      this.form.logo = file.response.data.url;
    },
    // 查看图片
    showImg(item) {
      this.previewImgList = [];
      this.showViewer = true;
      this.previewImgList = [item];
    },
    // 关闭图片
    closeViewer() {
      this.showViewer = false;
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 200;
    },
    search() {
      this.getList();
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/powerStation/list", that.queryInfo)
        .then(function(response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 保存
    handleSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var that = this;
          that.$http
            .post("/powerStation/save", that.form)
            .then(function(response) {
              if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true
                });
                that.showDialog = false;
                that.getList();
              } else {
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true
                });
              }
            })
            .catch(function(error) {});
        }
      });
    },
    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },
    handleDelte(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await this.$http.post("/powerStation/delete", {
            id: id
          });
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getList();
          }
        })
        .catch(err => {
          console.log(err, 495);
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    //分配
    async distribution(row) {
      this.multipleSelection = [];
      this.distributionDialog = true;
      this.powerStationCode = row.powerStationCode;
      this.queryInfo.condition.powerStationCode = row.powerStationCode;
      // this.queryInfo.condition.companyCode = row.companyCode;

      const res = await this.$http.post(
        "/userInfo/queryStationBindUserList",
        this.queryInfo
      );
      this.loading = false;
      if (res.data.code === 200) {
        this.companyUserList = res.data.data.data;
        this.$nextTick(() => {
          this.multipleSelection = res.data.data.data.filter(
            item => item.powerStationCode
          );
          this.setSelectedRows();
        });
      }
    },

    // 当前选择数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setSelectedRows() {
      this.$refs.multipleTable.clearSelection();
      console.log(this.multipleSelection, 1220);
      this.multipleSelection.forEach(item => {
        this.$refs.multipleTable.toggleRowSelection(item, true);
      });
    },
    
    // 保存分配
    async saveDistribution() {
      const data = {
        powerStationCode: this.powerStationCode,
        userCodeList: this.multipleSelection.map(item => item.userCode)
      };
      const res = await this.$http.post("/powerStationUser/bind", data);
      if (res.data.code === 200) {
        this.$notify.success({
          title: "提示",
          message: "分配成功",
          showClose: true
        });
        this.distributionDialog = false;
      } else {
        this.$notify.info({
          title: "提示",
          message: res.data.message,
          showClose: true
        });
      }
    }
  }
};
</script>
<style>
/* 滚动条整体样式 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 12px; /* 设置滚动条宽度 */
  height: 20px;
}
/* 滚动条轨道样式 */
.el-table__body-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* 设置滚动条轨道背景 */
}
/* 滚动条滑块样式 */
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #888; /* 设置滚动条滑块背景 */
}
/* 当鼠标悬停在滚动条上时滑块的样式 */
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* 设置滚动条滑块鼠标悬停背景 */
}
</style>

<style scoped lang="less">
.home-class {
  background: #223f6c;
  padding: 10px;
  height: 100%;
  .power-station {
    height: 100%;
    background: #2d5981;
    padding: 8px;
    .form-class {
      ::v-deep .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
        padding-top: 5px;
        box-sizing: border-box;
      }
      ::v-deep .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }
    ::v-deep .el-input-group__append {
      background: #409eff;
      color: #fff;
    }
    ::v-deep .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      text-align: left;
      line-height: normal;
      font-size: 21px;
    }
    ::v-deep .el-input__inner {
      border-radius: 5px;
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
      text-align: left;
      line-height: normal;
    }
    // ::v-deep .el-table--border,
    // .el-table--group {
    //   border: 1px solid rgba(151, 179, 203, 0.55);
    // }
    ::v-deep .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }
    ::v-deep .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }
    ::v-deep .el-table__body-wrapper {
      background: #2d5981;
    }
    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    ::v-deep .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }
    ::v-deep .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }
    ::v-deep .el-pager li {
      background: none;
      color: #f2f2f2;
    }
    ::v-deep .el-icon {
      color: #fff;
    }

    ::v-deep .el-pagination .btn-prev {
      background: none;
    }
    ::v-deep .el-pagination .btn-next {
      background: none;
    }
    ::v-deep .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }
    ::v-deep .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }
    ::v-deep .el-icon-close:before {
      color: #333;
    }
    ::v-deep .el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
}

.lis {
  height: 30px;
  line-height: 30px;
  text-align: start;
}
.active {
  background-color: #00b2e6;
  color: #fff;
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

::v-deep .el-form-item__label {
  text-align: end !important;
}
::v-deep .el-dialog__body {
  padding: 0 30px !important;
}

::v-deep .el-form-item--small.el-form-item {
  height: 36px !important;
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}
/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}
/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}
/deep/ .el-pager li {
  background: none;
  color: #f2f2f2;
}
/deep/ .el-icon {
  color: #fff;
}

/deep/ .el-pagination .btn-prev {
  background: none;
}
/deep/ .el-pagination .btn-next {
  background: none;
}
/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}
/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}
/deep/ .el-image-viewer__close {
  color: #fff;
}
</style>
